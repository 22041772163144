import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Section, Grid, Button, FormModal, Header } from 'src/components';

const SChip = styled.a`
  display: block;
  width: 100%;
  padding-top: 70%;
  background-color: var(--c-bg-0);
  background: ${(p) => (p.bg ? p.bg : 'var(--c-bg-0)')};
  border-radius: var(--br-box);
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    ._db {
      background-color: var(--c-cerise-1);
    }
  }
`;

const SDownloadButton = styled.div`
  height: 48px;
  width: 48px;
  position: absolute;
  top: 12px;
  right: 12px;
  filter: var(--svgFilter);
  background-image: url(/iconic/download.svg);
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: calc(var(--br-box) - 6px);
`;

const PressKitSection = ({
  title = 'Press Kit',
  subtitle = 'By using this Site, I agree to its Terms & Conditions. All content downloaded from the Site (photography, audio and video, etc.) may be used for editorial purposes only. Any other use of Site content including personal or commercial use is strictly prohibited.',
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <FormModal open={open} title="Get in touch" onClose={() => setOpen(false)} />

      <Header narrow title={title} text={subtitle}>
        <Button
          onPress={() => {
            setOpen(true);
          }}
        >
          Get in touch
        </Button>
      </Header>

      <Section small bg="var(--c-bg-gray)">
        <Container narrow>
          <Grid num={2}>
            <div>
              <eb className="pad-y2">Brand</eb>
              <SChip
                bg="url(/new/brand.jpg) center/cover"
                target="_blank"
                href="/misc/catch_logos.zip"
                style={{ filter: 'var(--svgFilter)' }}
              >
                <SDownloadButton className="_db" style={{ filter: 'none' }} />
              </SChip>
            </div>
          </Grid>
        </Container>
      </Section>
    </>
  );
};

export default PressKitSection;
