import React from 'react';
import { navigate } from 'gatsby';
import { Button, Grid, Section, SmolBlock } from 'src/components';
import { $t } from 'src/utils';

const ReasonsToBelieveSection = ({ showCTA }: { showCTA?: boolean }) => {
  return (
    <Section
      title={$t({
        id: 'section.reasons-to-believe-title',
        defaultMessage: 'We do the research. You get the benefits.',
      })}
      subtitle={$t({
        id: 'section.reasons-to-believe-subtitle',
        defaultMessage: 'Here are just a few reasons to love Catch:',
      })}
      bg="var(--g-cerise-light)"
    >
      <Grid num={3} top>
        <SmolBlock
          icon="/iconic/target.svg"
          alt="target icon"
          title={$t({
            id: 'section.reasons-to-believe-item-1-title',
            defaultMessage: 'Tailored for you.',
          })}
          text={$t({
            id: 'section.reasons-to-believe-item-1-description',
            defaultMessage: 'We sift through hundreds of plans to find the right ones for you.',
          })}
        />
        <SmolBlock
          icon="/iconic/trophy.svg"
          alt="trophy icon"
          title={$t({
            id: 'section.reasons-to-believe-item-2-title',
            defaultMessage: 'The best plans at the best price.',
          })}
          text={$t({
            id: 'section.reasons-to-believe-item-2-description',
            defaultMessage:
              'We apply tax credits instantly. You might be surprised at what you can save!',
          })}
        />
        <SmolBlock
          icon="/iconic/annotation.svg"
          alt="chat icon"
          title={$t({
            id: 'section.reasons-to-believe-item-3-title',
            defaultMessage: 'Absolutely free.',
          })}
          text={$t({
            id: 'section.reasons-to-believe-item-3-description',
            defaultMessage:
              "Our service is available at no cost. We're a broker, which means we may earn a commission off of insurance you buy.",
          })}
        />
      </Grid>
      {showCTA && (
        <Button
          large
          purpose="Sign up"
          onPress={() => {
            navigate('/explorer/zip');
          }}
        >
          {$t({ id: 'home.btn', defaultMessage: 'Get Started' })}
        </Button>
      )}
    </Section>
  );
};

export default ReasonsToBelieveSection;
