import { navigate } from 'gatsby';
import React from 'react';
import { Button, Grid, Section, SmolBlock } from 'src/components';
import { $t } from 'src/utils';

const GetCoverageSection = () => {
  return (
    <Section
      title={$t({
        id: 'section.get-coverage-title',
        defaultMessage: 'Get insurance with Catch',
      })}
      subtitle={$t({
        id: 'section.get-coverage-subtitle',
        defaultMessage: 'Signing up for benefits is as easy as 1, 2, 3:',
      })}
    >
      <Grid num={3} top>
        <SmolBlock
          step="1"
          color="var(--g-cerise)"
          stepColor="#FFF"
          title={$t({
            id: 'section.get-coverage-item-1-title',
            defaultMessage: 'Enter your info.',
          })}
          text={$t({
            id: 'section.get-coverage-item-1-description',
            defaultMessage:
              "Share what's most important to you, like how you plan to use your health coverage.",
          })}
        />
        <SmolBlock
          step="2"
          color="var(--g-cerise)"
          stepColor="#FFF"
          title={$t({
            id: 'section.get-coverage-item-2-title',
            defaultMessage: 'Review recommended plans.',
          })}
          text={$t({
            id: 'section.get-coverage-item-2-description',
            defaultMessage:
              "We'll show you the plans that work best for your needs, goals, and budget.",
          })}
        />
        <SmolBlock
          step="3"
          color="var(--g-cerise)"
          stepColor="#FFF"
          title={$t({
            id: 'section.get-coverage-item-3-title',
            defaultMessage: 'Get coverage (and peace of mind).',
          })}
          text={$t({
            id: 'section.get-coverage-item-3-description',
            defaultMessage:
              "Once you've chosen a plan, we'll help you apply and enroll. We'll also check for savings.",
          })}
        />
      </Grid>
      <Button large onPress={() => navigate('/explorer/zip')}>
        {$t({ id: 'section.get-coverage-cta', defaultMessage: 'Explore plans' })}
      </Button>
    </Section>
  );
};

export default GetCoverageSection;
